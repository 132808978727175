<template>
  <div>
    <my-header></my-header>
    <div class="box">
      <el-row :gutter="20">
        <el-col :span="6" :push="18">
          <!-- 添加客户 -->
          <div class="grid-content bg-purple">
            <el-button type="primary" @click="adddialogFormVisible = true"
              >添加客户</el-button
            >
          </div>
          <el-dialog title="添加客户" :visible.sync="adddialogFormVisible">
            <el-form :model="form">
              <el-form-item label="负责人姓名" :label-width="formLabelWidth">
                <el-input v-model="form.name" autocomplete="off"></el-input>
              </el-form-item>
              <el-form-item label="手机号" :label-width="formLabelWidth">
                <el-input v-model="form.phone" autocomplete="off"></el-input>
              </el-form-item>
              <el-form-item label="性别" :label-width="formLabelWidth">
                <el-select v-model="form.sex" placeholder="请选择性别">
                  <el-option label="男" value="1"></el-option>
                  <el-option label="女" value="2"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="购买意愿" :label-width="formLabelWidth">
                <el-select
                  v-model="form.status"
                  placeholder="请选择购买意愿程度"
                >
                  <el-option label="较弱" value="1"></el-option>
                  <el-option label="一般" value="2"></el-option>
                  <el-option label="强烈" value="3"></el-option>
                  <el-option label="废弃" value="4"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="项目需求" :label-width="formLabelWidth">
                <el-input type="textarea" v-model="form.need"></el-input>
              </el-form-item>
              <el-form-item label="项目名称" :label-width="formLabelWidth">
                <el-input
                  v-model="form.entryname"
                  autocomplete="off"
                ></el-input>
              </el-form-item>
              <el-form-item label="项目地址" :label-width="formLabelWidth">
                <el-input v-model="form.address" autocomplete="off"></el-input>
              </el-form-item>
              <el-form-item
                label="预计最佳跟踪时间"
                :label-width="formLabelWidth"
              >
                <el-date-picker
                  v-model="form.starttrackingtime"
                  type="date"
                  placeholder="选择日期时间"
                  value-format="yyyy-MM-dd"
                >
                </el-date-picker>
              </el-form-item>
              <el-form-item label="预计需求时间" :label-width="formLabelWidth">
                <el-date-picker
                  v-model="form.demandtime"
                  type="date"
                  placeholder="选择日期时间"
                  value-format="yyyy-MM-dd"
                >
                </el-date-picker>
              </el-form-item>
              <el-form-item label="采购单位" :label-width="formLabelWidth">
                <el-input
                  v-model="form.purchasingUnit"
                  autocomplete="off"
                ></el-input>
              </el-form-item>
              <el-form-item label="备注" :label-width="formLabelWidth">
                <el-input type="textarea" v-model="form.message"></el-input>
              </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
              <el-button @click="adddialogFormVisible = false">取 消</el-button>
              <el-button type="primary" @click="addClient">确 定</el-button>
            </div>
          </el-dialog>
        </el-col>
      </el-row>
      <el-table :data="ClientList" border style="width: 100%; margin-top: 25px">
        <el-table-column fixed prop="createTime" label="创建日期" width="170">
        </el-table-column>
        <el-table-column prop="name" label="姓名" width="120">
        </el-table-column>
        <el-table-column prop="phone" label="电话" width="150">
        </el-table-column>
        <el-table-column prop="sex" label="性别"> </el-table-column>
        <el-table-column prop="status" label="购买意愿"> </el-table-column>
        <!-- <el-table-column prop="entryname" label="项目名称" width="170">
        </el-table-column> -->
        <el-table-column prop="address" label="项目地址" width="170">
        </el-table-column>
        <!-- <el-table-column
          prop="starttrackingtime"
          label="预计最佳开始追踪时间"
          width="170"
        >
        </el-table-column>
        <el-table-column prop="demandtime" label="预计需求时间" width="170">
        </el-table-column> -->
        <!-- <el-table-column prop="need" label="客户需求" width="170">
        </el-table-column> -->
        <el-table-column prop="purchasingUnit" label="采购单位" width="170">
        </el-table-column>
        <el-table-column prop="message" label="备注" width="170">
        </el-table-column>

        <el-table-column fixed="right" label="操作" width="220">
          <template slot-scope="scope">
            <el-button
              size="small"
              @click="lookhandleClick(scope.row, scope.$index)"
              >查看</el-button
            >
            <el-button
              type="primary"
              size="small"
              @click="undatehandleClick(scope.row)"
              >编辑</el-button
            >
            <el-popconfirm
              @onConfirm="delhandleClick(scope.row)"
              title="这是一段内容确定删除吗？"
            >
              <el-button
                slot="reference"
                style="margin-left: 10px"
                type="danger"
                size="small"
                >删除</el-button
              >
            </el-popconfirm>
          </template>
        </el-table-column>
      </el-table>
      <!-- 编辑用户 -->
      <el-dialog title="更新客户信息" :visible.sync="undatedialogFormVisible">
        <el-form :model="updateform">
          <el-form-item label="负责人姓名" :label-width="formLabelWidth">
            <el-input v-model="updateform.name" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="手机号" :label-width="formLabelWidth">
            <el-input v-model="updateform.phone" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="性别" :label-width="formLabelWidth">
            <el-select v-model="updateform.sex" placeholder="请选择性别">
              <el-option label="男" value="1"></el-option>
              <el-option label="女" value="2"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="购买意愿" :label-width="formLabelWidth">
            <el-select
              v-model="updateform.status"
              placeholder="请选择购买意愿程度"
            >
              <el-option label="较弱" value="1"></el-option>
              <el-option label="一般" value="2"></el-option>
              <el-option label="强烈" value="3"></el-option>
              <el-option label="废弃" value="4"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="项目需求" :label-width="formLabelWidth">
            <el-input type="textarea" v-model="updateform.need"></el-input>
          </el-form-item>
          <el-form-item label="项目名称" :label-width="formLabelWidth">
            <el-input
              v-model="updateform.entryname"
              autocomplete="off"
            ></el-input>
          </el-form-item>
          <el-form-item label="项目地址" :label-width="formLabelWidth">
            <el-input
              v-model="updateform.address"
              autocomplete="off"
            ></el-input>
          </el-form-item>
          <el-form-item label="预计最佳跟踪时间" :label-width="formLabelWidth">
            <el-date-picker
              v-model="updateform.starttrackingtime"
              type="date"
              placeholder="选择日期时间"
              value-format="yyyy-MM-dd"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="预计需求时间" :label-width="formLabelWidth">
            <el-date-picker
              v-model="updateform.demandtime"
              type="date"
              placeholder="选择日期时间"
              value-format="yyyy-MM-dd"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="采购单位" :label-width="formLabelWidth">
            <el-input
              v-model="updateform.purchasingUnit"
              autocomplete="off"
            ></el-input>
          </el-form-item>
          <el-form-item label="备注" :label-width="formLabelWidth">
            <el-input type="textarea" v-model="updateform.message"></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="updatedialogFormVisible = false">取 消</el-button>
          <el-button type="primary" @click="updateClient">确 定</el-button>
        </div>
      </el-dialog>
      <!-- 分页 -->
      <el-pagination
        background
        layout="prev, pager, next"
        :total="total"
        :page-size="pageSize"
        @current-change="handleCurrentChange"
        :hide-on-single-page="true"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
export default {
  inject: ["reload"],
  data() {
    return {
      // 添加客户模块显示隐藏
      adddialogFormVisible: false,
      //   编辑客户模块显示隐藏
      undatedialogFormVisible: false,
      formLabelWidth: "130px",
      //   客户列表
      ClientList: [],
      //   客户详细信息
      // 添加客户
      form: {
        phone: "", //string，项目负责人电话
        name: "", //string,项目负责人姓名
        sex: "", //string,性别 1.男2.女
        status: "", //string,购买意愿程度  1.（较弱）2.（一般）3.强烈4.废弃
        need: "", //string,项目需求
        entryname: "", //string, 项目名称
        address: "", //string,项目地址
        starttrackingtime: "", //Date,预计最佳开始跟踪时间
        demandtime: "", //Date,预计需求时间
        message: "",
        purchasingUnit: "",
      },
      updateform: {
        id: "",
        agentUserId: "",
        phone: "", //string，项目负责人电话
        name: "", //string,项目负责人姓名
        sex: "", //string,性别 1.男2.女
        status: "", //string,购买意愿程度  1.（较弱）2.（一般）3.强烈4.废弃
        need: "", //string,项目需求
        entryname: "", //string, 项目名称
        address: "", //string,项目地址
        starttrackingtime: "", //Date,预计最佳开始跟踪时间
        demandtime: "", //Date,预计需求时间
        message: "",
        purchasingUnit: "",
      },
      total: 0,
      pageSize: 10,
      pageNum: 1,
    };
  },
  methods: {
    async addClient() {
      this.adddialogFormVisible = false;
      const res = await this.$http({
        method: "post",
        url: "front/client/saveClient",
        data: {
          ...this.form,
        },
      });
      if (res.data.status == 200) {
        this.reload();
        this.$message({
          message: "添加成功",
          type: "success",
        });
      }
    },
    // 更新客户信息
    async undatehandleClick(row) {
      console.log(row);
      this.undatedialogFormVisible = true;
      var {
        id,
        agentUserId,
        phone,
        name,
        sex,
        status,
        need,
        entryname,
        address,
        starttrackingtime,
        demandtime,
        message,
        purchasingUnit,
      } = row;
      this.updateform.id = id;
      this.updateform.purchasingUnit = purchasingUnit;
      this.updateform.agentUserId = agentUserId;
      this.updateform.phone = phone;
      this.updateform.name = name;
      this.updateform.sex = sex;
      this.updateform.status = status;
      this.updateform.need = need;
      this.updateform.entryname = entryname;
      this.updateform.address = address;
      this.updateform.starttrackingtime = starttrackingtime.replace(
        " 00:00:00",
        ""
      );
      this.updateform.demandtime = demandtime.replace(" 00:00:00", "");
      this.updateform.message = message;
      console.log(this.updateform);
    },
    // 确定更新客户信息
    async updateClient() {
      const res = await this.$http({
        method: "post",
        url: "front/client/updateClient",
        data: {
          ...this.updateform,
        },
      });
      if (res.data.status == 200) {
        this.reload();
        this.undatedialogFormVisible = false;
        this.$message({
          message: "编辑成功",
          type: "success",
        });
      }
    },
    async getClient() {
      const res = await this.$http({
        method: "get",
        url: `front/client/selectClientByUserId?sortType=create_time desc&pageSize=${this.pageSize}&pageNum=${this.pageNum}`,
      });
      res.data.data.list.map((v, k) => {
        v.sex == 1 ? (v.sex = "男") : v.sex == 2 ? (v.sex = "女") : "未知";
        v.status == 1
          ? (v.status = "较弱")
          : v.status == 2
          ? (v.status = "一般")
          : v.status == 3
          ? (v.status = "强烈")
          : v.status == 4
          ? (v.status = "废弃")
          : "未知";
      });
      console.log(res.data.data);
      this.total = res.data.data.total;
      this.ClientList = res.data.data.list;
    },
    async delhandleClick(row) {
      const res = await this.$http({
        method: "post",
        url: "front/client/deleteClient",
        data: {
          id: row.id,
        },
      });
      if (res.data.status == 200) {
        this.reload();
        this.$message({
          message: "删除成功",
          type: "success",
        });
      }
    },
    async lookhandleClick(row, k) {
      const res = await this.$http({
        method: "get",
        url: `front/client/selectClientById?id=${row.id}`,
      });
      if (res.data.status == 200) {
        res.data.data.sex == 1
          ? (res.data.data.sex = "男")
          : res.data.data.sex == 2
          ? (res.data.data.sex = "女")
          : "未知";
        res.data.data.status == 1
          ? (res.data.data.status = "较弱")
          : res.data.data.status == 2
          ? (res.data.data.status = "一般")
          : res.data.data.status == 3
          ? (res.data.data.status = "强烈")
          : res.data.data.status == 4
          ? (res.data.data.status = "废弃")
          : "未知";
        this.$router.push({
          path: "/MyClientDetail",
          query: {
            item: JSON.stringify(res.data.data),
          },
        });
      }
    },
    handleCurrentChange(p) {
      this.pageNum = p;
      this.getClient();
    },
  },
  created() {
    this.getClient();
  },
};
</script>

<style lang="scss" scoped>
.box {
  margin: 0 auto;
  margin-top: 160px;
  width: 80%;
  min-width: 1000px;
  & ::v-deep .el-dialog {
    min-width: 800px;
  }
  & ::v-deep .el-pagination {
    display: flex;
    justify-content: flex-end;
    margin: 10px 30px 10px 0px;
  }
}
</style>